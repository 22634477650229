<template>
	<svg
		width="118"
		height="118"
		viewBox="0 0 118 118"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M93.9278 0H23.1013C10.3428 0 0 10.3428 0 23.1013V93.9278C0 106.686 10.3428 117.029 23.1013 117.029H93.9278C106.686 117.029 117.029 106.686 117.029 93.9278V23.1013C117.029 10.3428 106.686 0 93.9278 0Z"
			fill="url(#paint0_radial_174_336)"
		/>
		<path
			d="M93.9278 0H23.1013C10.3428 0 0 10.3428 0 23.1013V93.9278C0 106.686 10.3428 117.029 23.1013 117.029H93.9278C106.686 117.029 117.029 106.686 117.029 93.9278V23.1013C117.029 10.3428 106.686 0 93.9278 0Z"
			fill="#0B3D3D"
			fill-opacity="0.8"
		/>
		<path
			d="M95.1879 33.1294L91.4077 32.0268C80.1721 28.7716 67.9389 30.9242 58.5409 37.7496C52.083 33.0769 43.9975 30.5042 36.1746 30.5042H21.8938V41.0048H36.2796C42.2649 41.0048 48.1978 42.9999 52.923 46.6226L58.5934 50.9279L64.2637 46.6226C70.144 42.1599 77.5469 40.2698 84.7923 41.2673V76.1818C75.5518 75.2367 66.2063 77.7044 58.6459 83.2172C51.0854 77.7044 41.6349 75.2367 32.4994 76.1818V52.8705H21.9988V86.4724H95.3454V33.1294H95.1879Z"
			fill="#58FF9B"
		/>
		<defs>
			<radialGradient
				id="paint0_radial_174_336"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(117.24 -101.5) rotate(105.042) scale(226.282)"
			>
				<stop offset="0.445162" stop-color="#1F7676" />
				<stop offset="1" stop-color="#0A4B4B" />
			</radialGradient>
		</defs>
	</svg>
</template>
